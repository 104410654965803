.login_bg {
  background-image: url(../images/login_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.container-bg {
  background-color: #202022;
}

.apexcharts-canvas {
  background-image: url('../images/chart-bg.svg');
}

.ant-image-preview-img {
  display: inline-block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
